<template>
  <div class="case-container">
    <div class="case-top">
      <div class="case-top-profile">
        <div class="case-top-profile-name">{{ currentCase.name }}</div>
        <div class="case-top-profile-code-title">{{ $t('PR001.idTitle') }}</div>
        <div class="case-top-profile-code">{{ customId }}</div>
      </div>
      <div class="case-top-condition-title">
        {{ $t('PR001.queryConditionTitle') }}
      </div>
      <div class="case-top-control"></div>
      <a-range-picker
        class="cast-top-select-date"
        :ranges="ranges"
        format="YYYY/MM/DD"
        :disabled-date="disabledDate"
        v-model:value="fromToDatelist"
        @change="onChangeDate"
      >
        <template #suffixIcon>
          <CalendarOutlined />
        </template>
      </a-range-picker>
    </div>
    <div class="case-middle">
      <CaseStatistics
        :fid="fid"
        :fromDateTs="fromDate"
        :toDateTs="toDate"
        :refresh="refresh"
      />
    </div>
    <div class="case-list">
      <a-row
        type="flex"
        justify="center"
        style="padding-top: 18px; padding-bottom: 40px"
      >
        <div class="tab-control-area">
          <div
            @click="tabClick('BloodPressureChart')"
            :class="{ active: tabName == 'BloodPressureChart' }"
          >
            {{ $t('PR001.bloodPressureCaptial') }}
          </div>
          <div
            @click="tabClick('BloodGlucoseChart')"
            :class="{ active: tabName == 'BloodGlucoseChart' }"
          >
            {{ $t('PR001.glucoseCaptial') }}
          </div>
          <template v-if="displayMode == 'Chart'">
            <div style="cursor: auto; color: #a9a9a9" disabled>
              {{ $t('PR001.bodyWeightCaptial') }}
            </div>
            <div style="cursor: auto; color: #a9a9a9" disabled>
              {{ $t('PR001.spo2') }}
            </div>
            <div style="cursor: auto; color: #a9a9a9" disabled>
              {{ $t('PR001.bodyTemperature') }}
            </div>
          </template>
          <template v-if="displayMode == 'Table'">
            <div
              @click="tabClick('BodyWeightChart')"
              :class="{ active: tabName == 'BodyWeightChart' }"
            >
              {{ $t('PR001.bodyWeightCaptial') }}
            </div>
            <div
              @click="tabClick('Spo2Chart')"
              :class="{ active: tabName == 'Spo2Chart' }"
            >
              {{ $t('PR001.spo2') }}
            </div>
            <div
              @click="tabClick('BodyTemperatureChart')"
              :class="{ active: tabName == 'BodyTemperatureChart' }"
            >
              {{ $t('PR001.bodyTemperature') }}
            </div>
          </template>
        </div>
        <div class="siwtch-tab-list-btn" @click="clickSwitchMode">
          <unordered-list-outlined
            v-if="displayMode == 'Chart'"
            style="font-size: 25px"
          />
          <bar-chart-outlined
            v-if="displayMode == 'Table'"
            style="font-size: 25px"
          />
          <!-- <a-button @click="clickSwitchMode" type="link" size="large">
            <template #icon>
              <unordered-list-outlined
                v-if="displayMode == 'Table'"
                style="font-size: 25px"
              />
              <bar-chart-outlined
                v-if="displayMode == 'Chart'"
                style="font-size: 25px"
              />
            </template>
          </a-button> -->
        </div>
      </a-row>
      <!-- <div class="case-list-title">{{ $t('PR001.healthLog') }}</div> -->
      <div class="tab-function-area" v-if="displayMode === 'Chart'">
        <transition>
          <component
            :is="tabName"
            :start-ts="fromDate"
            :end-ts="toDate"
            :fid="fid"
            :refresh="refresh"
          ></component>
        </transition>
        <!-- <transition>
          <BloodPressureChart
            :start-ts="fromDate"
            :end-ts="toDate"
            :fid="fid"
            :refresh="refresh"
          ></BloodPressureChart>
        </transition> -->
      </div>
      <a-table
        v-if="displayMode === 'Table'"
        class="case-list-table"
        :columns="detailColumns"
        :data-source="detailData"
        rowKey="{record => record.id}"
        size="small"
        :scroll="tableScrollSetting"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #tmpRecordTimeTitle class="group1">
          <div class="cusTableTitle">{{ $t('PR001.recordTime') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpRecordTime="{ text }">
          <span class="cusRecordTime">
            {{ convertTimeFormat(text) }}
          </span>
        </template>
        <template #tmpSystolicTitle>
          <div class="cusTableTitle">{{ $t('PR001.systolic') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpDiastolicTitle>
          <div class="cusTableTitle">{{ $t('PR001.diastolic') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.bloodPressureUnit') }}</div>
        </template>
        <template #tmpPpTitle>
          <div class="cusTableTitle">{{ $t('PR001.pp') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpPulseTitle>
          <div class="cusTableTitle">{{ $t('PR001.pulse') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.pulseUnit') }}</div>
        </template>
        <template #tmpArrTitle>
          <div class="cusTableTitle">{{ $t('PR001.arrTitle') }}</div>
          <div class="cusTableUnit">ARR</div>
        </template>
        <template #tmpAfibTitle>
          <div class="cusTableTitle">{{ $t('PR001.afibTitle') }}</div>
          <div class="cusTableUnit">aFib</div>
        </template>
        <template #tmpPcTitle>
          <div class="cusTableTitle">{{ $t('PR001.pcTitle') }}</div>
          <div class="cusTableUnit">PC</div>
        </template>
        <!-- <template #tmpArrTitle>
          <div class="cusTableTitle">ARR</div>
          <div class="cusTableTitle">PC</div>
          <div class="cusTableTitle">AFRIB</div>
        </template> -->
        <template #tmpFastingTitle>
          <div class="cusTableTitle">{{ $t('PR001.fasting') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpBeforeMealTitle>
          <div class="cusTableTitle">{{ $t('PR001.beforeMeal') }}</div>
          <div class="cusTableUnit">{{ getGlucoseUnit() }}</div>
        </template>
        <template #tmpAfterMealTitle>
          <div class="cusTableTitle">{{ $t('PR001.afterMeal') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpSpo2Title>
          <div class="cusTableTitle">{{ $t('PR001.spo2') }}</div>
          <div class="cusTableUnit">%</div>
        </template>
        <template #tmpActTitle>
          <div class="cusTableTitle">{{ $t('PR001.act') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.visceralFatUnit') }}</div>
        </template>
        <template #tmpBodyTemperatureTitle>
          <div class="cusTableTitle">{{ $t('PR001.bodyTemperature') }}</div>
          <div class="cusTableUnit">{{ getTemperatureUnit() }}</div>
        </template>
        <template #tmpHeightTitle>
          <div class="cusTableTitle">{{ $t('PR001.height') }}</div>
          <div class="cusTableUnit">{{ getHeightUnit() }}</div>
        </template>
        <template #tmpBodyWeightTitle>
          <div class="cusTableTitle">{{ $t('PR001.bodyWeight') }}</div>
          <div class="cusTableUnit">{{ getWeightUnit() }}</div>
        </template>
        <template #tmpBmiTitle>
          <div class="cusTableTitle">{{ $t('PR001.bmi') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpBodyFatTitle>
          <div class="cusTableTitle">{{ $t('PR001.bodyFat') }}</div>
          <div class="cusTableUnit">%</div>
        </template>
        <template #tmpBodyFatMassTitle>
          <div class="cusTableTitle">{{ $t('PR001.bodyFatMass') }}</div>
          <div class="cusTableUnit">{{ getWeightUnit() }}</div>
        </template>
        <template #tmpMuscleMassTitle>
          <div class="cusTableTitle">{{ $t('PR001.muscleMass') }}</div>
          <div class="cusTableUnit">{{ getWeightUnit() }}</div>
        </template>
        <template #tmpMusclePercentageTitle>
          <div class="cusTableTitle">{{ $t('PR001.musclePercentage') }}</div>
          <div class="cusTableUnit">%</div>
        </template>
        <template #tmpSkeletalMuscleMassPercentageTitle>
          <div class="cusTableTitle">
            {{ $t('PR001.skeletalMuscleMassPercentage') }}
          </div>
          <div class="cusTableUnit">%</div>
        </template>
        <template #tmpBodyWaterTitle>
          <div class="cusTableTitle">
            {{ $t('PR001.bodyWater') }}
          </div>
          <div class="cusTableUnit">%</div>
        </template>
        <template #tmpVisceralFatTitle>
          <div class="cusTableTitle">{{ $t('PR001.visceralFat') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.visceralFatUnit') }}</div>
        </template>
        <template #tmpBmrTitle>
          <div class="cusTableTitle">{{ $t('PR001.bmr') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.bmrUnit') }}</div>
        </template>
        <template #tmpBoneTitle>
          <div class="cusTableTitle">{{ $t('PR001.bone') }}</div>
          <div class="cusTableUnit">{{ getWeightUnit() }}</div>
        </template>
        <template #tmpCustomCell="{ text }">
          <span class="cusTableCell">
            {{ text }}
          </span>
        </template>
        <template #tmpMathRoundCell="{ text }">
          <span class="cusTableCell">
            {{ roundDec(text, 0) }}
          </span>
        </template>
        <template #tmpMathRoundOneCell="{ text }">
          <span class="cusTableCell">
            {{ roundDec(text, 1) }}
          </span>
        </template>
        <template #tmpMathRoundOrDashCell="{ text }">
          <span class="cusTableCell">
            {{ checkIfZero(roundDec(text, 0)) }}
          </span>
        </template>
        <template #tmpMathRoundOneOrDashCell="{ text }">
          <span class="cusTableCell">
            {{ checkIfZero(roundDec(text, 1)) }}
          </span>
        </template>
        <template #tmpSystolicCustomCell="{ record, text }">
          <span
            :class="{
              'normal-color': record?.systolicWarningLevel == 0,
              'warning-color': record?.systolicWarningLevel == 1,
              'abnormal-color': record?.systolicWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ roundDec(text, 1) }}
          </span>
        </template>
        <template #tmpDiastolicCustomCell="{ record, text }">
          <span
            :class="{
              'normal-color': record?.diastolicWarningLevel == 0,
              'warning-color': record?.diastolicWarningLevel == 1,
              'abnormal-color': record?.diastolicWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ roundDec(text, 1) }}
          </span>
        </template>
        <template #tmpPulseCustomCell="{ record, text }">
          <span
            :class="{
              'normal-color': record?.pulseWarningLevel == 0,
              'warning-color': record?.pulseWarningLevel == 1,
              'abnormal-color': record?.pulseWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ roundDec(text, 1) }}
          </span>
        </template>
        <template #tmpBloodGlucoseFastingCell="{ record }">
          <span
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ customBloodGlucoseFasting(record) }}
          </span>
        </template>
        <template #tmpBloodGlucoseBeforeCell="{ record }">
          <span
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ customBloodGlucoseBefore(record) }}
          </span>
        </template>
        <template #tmpBloodGlucoseAfterCell="{ record }">
          <span
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ customBloodGlucoseAfter(record) }}
          </span>
        </template>
        <template #tmpPPCell="{ record, text }">
          <span
            :class="{
              'normal-color': record?.ppWarningLevel == 0,
              'warning-color': record?.ppWarningLevel == 1,
              'abnormal-color': record?.ppWarningLevel == 2,
            }"
            class="cusTableCellNoColor"
          >
            {{ customPP(roundDec(text, 0)) }}
          </span>
        </template>
        <template #tmpHeightCell="{ text }">
          <span class="cusTableCell">
            {{ customHeight(text) }}
          </span>
        </template>
        <template #tmpWeightCell="{ text }">
          <span class="cusTableCell">
            {{ customWeight(text) }}
          </span>
        </template>
        <template #tmpMuscleMassCell="{ record }">
          <span class="cusTableCell">
            {{ getMuscleMassAvg(record) }}
          </span>
        </template>
        <template #tmpBodyFatMassCell="{ record }">
          <span class="cusTableCell">
            {{ getBodyFatMass(record) }}
          </span>
        </template>
        <template #tmpMusclePercentageCell="{ record }">
          <span class="cusTableCell">
            {{ getMusclePercentage(record) }}
          </span>
        </template>
        <template #tmpArrAfibPcCell="{ text }">
          <span class="cusTableCell">
            {{ transferArrAfibPc(text) }}
          </span>
        </template>

        <!-- <template #customTitle>
          <span>
            <smile-outlined />
            Name
          </span>
        </template> -->
      </a-table>
    </div>
  </div>
</template>
<script>
  import { CalendarOutlined } from '@ant-design/icons-vue'
  import { mapActions, mapGetters } from 'vuex'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'
  import CaseStatistics from './caseStatistics.vue'
  import { roundDecimal } from '@/utils/pocketKnives'
  import {
    UnorderedListOutlined,
    BarChartOutlined,
  } from '@ant-design/icons-vue'
  import BloodPressureChart from './BloodPressureChart.vue'
  import BloodGlucoseChart from './BloodGlucoseChart.vue'
  // import BodyWeightChart from './BodyWeightChart.vue'
  // import BodyTemperatureChart from './BodyTemperatureChart.vue'
  // import Spo2Chart from './Spo2Chart.vue'

  export default {
    name: 'MeasurementPeriod',
    components: {
      CalendarOutlined,
      CaseStatistics,
      UnorderedListOutlined,
      BarChartOutlined,
      BloodPressureChart,
      BloodGlucoseChart,
      // BodyWeightChart,
      // BodyTemperatureChart,
      // Spo2Chart,
    },
    data() {
      const ranges = {}
      // 使用 dayjs 替代 moment
      ranges[this.$t('DatePicker.today')] = [dayjs(), dayjs()]
      ranges[this.$t('DatePicker.yesterday')] = [
        dayjs().subtract(1, 'day'),
        dayjs().subtract(1, 'day'),
      ]
      ranges[this.$t('DatePicker.thisMonth')] = [
        dayjs().startOf('month'),
        dayjs().endOf('month'),
      ]
      ranges[this.$t('DatePicker.latestMonth')] = [
        dayjs().subtract(1, 'month'),
        dayjs(),
      ]
      ranges[this.$t('DatePicker.latestThreeMonths')] = [
        dayjs().subtract(3, 'month'),
        dayjs(),
      ]
      ranges[this.$t('DatePicker.latestYear')] = [
        dayjs().subtract(1, 'year').add(1, 'day'),
        dayjs(),
      ]
      return {
        tabName: 'BloodPressureChart',
        i18n: useI18n(),
        isNaviTypeCase: false,
        fid: '',
        //customId: '',
        refresh: 0,
        fromDate: 0,
        toDate: 0,
        fromToDatelist: [],
        ranges,
        displayMode: 'Chart', // Table / Chart
        pagination: {
          //position: 'top',
          current: 1,
          pageSize: 10,
          total: 0,
          showTotal: this.getTotalCountString,
        },
      }
    },
    computed: {
      ...mapGetters({
        measurementDataAll: 'measurement/measurementDataAll',
        amount: 'measurement/measurementDataDetailAmount',
        detailData: 'measurement/measurementDataDetail',
        currentCase: 'case/currentCase',
        measureThreshold: 'measureThreshold/measureThreshold',
      }),
      customId: function () {
        if (this.currentCase && this.currentCase.identifications) {
          for (let i = 0; i < this.currentCase.identifications.length; i++) {
            if (this.currentCase.identifications[i].type === 'CUSTOM_ID') {
              return this.currentCase.identifications[i].value
            }
          }
        }
        return ''
      },
      toChildEndTs: function () {
        return this.toDate
      },
      detailColumns: function () {
        if (this.tabName === 'BloodPressureChart') {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '舒張壓',
              dataIndex: 'systolic',
              key: 'systolic',
              slots: {
                title: 'tmpSystolicTitle',
                // customRender: 'tmpMathRoundCell',
                customRender: 'tmpSystolicCustomCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '收縮壓',
              dataIndex: 'diastolic',
              key: 'diastolic',
              slots: {
                title: 'tmpDiastolicTitle',
                // customRender: 'tmpMathRoundCell',
                customRender: 'tmpDiastolicCustomCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '脈壓差',
              dataIndex: 'pp',
              key: 'pp',
              slots: {
                title: 'tmpPpTitle',
                customRender: 'tmpPPCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '脈搏',
              dataIndex: 'pulse',
              key: 'pulse',
              slots: {
                title: 'tmpPulseTitle',
                // customRender: 'tmpMathRoundCell',
                customRender: 'tmpPulseCustomCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: 'ARR,PC,AFRIB',
              dataIndex: 'arr',
              key: 'arr',
              width: '70px',
              slots: {
                title: 'tmpArrTitle',
                customRender: 'tmpArrAfibPcCell',
              },
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: 'ARR,PC,AFRIB',
              dataIndex: 'afib',
              key: 'afib',
              width: '70px',
              slots: {
                title: 'tmpAfibTitle',
                customRender: 'tmpArrAfibPcCell',
              },
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: 'ARR,PC,AFRIB',
              dataIndex: 'pc',
              key: 'pc',
              width: '70px',
              slots: {
                title: 'tmpPcTitle',
                customRender: 'tmpArrAfibPcCell',
              },
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
          ]
        } else if (this.tabName === 'BloodGlucoseChart') {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '空腹血糖',
              dataIndex: 'fasting',
              key: 'fasting',
              slots: {
                title: 'tmpFastingTitle',
                customRender: 'tmpBloodGlucoseFastingCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '餐前血糖',
              dataIndex: 'beforeMeal',
              key: 'beforeMeal',
              slots: {
                title: 'tmpBeforeMealTitle',
                customRender: 'tmpBloodGlucoseBeforeCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '餐後血糖',
              dataIndex: 'afterMeal',
              key: 'afterMeal',
              slots: {
                title: 'tmpAfterMealTitle',
                customRender: 'tmpBloodGlucoseAfterCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
          ]
        } else if (this.tabName === 'BodyWeightChart') {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '體重',
              dataIndex: 'weight',
              key: 'weight',
              slots: {
                title: 'tmpBodyWeightTitle',
                customRender: 'tmpWeightCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: 'BMI',
              dataIndex: 'bmi',
              key: 'bmi',
              slots: {
                title: 'tmpBmiTitle',
                customRender: 'tmpMathRoundOneOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '體脂率',
              dataIndex: 'bodyFat',
              key: 'bodyFat',
              slots: {
                title: 'tmpBodyFatTitle',
                customRender: 'tmpMathRoundOneOrDashCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '總體脂量',
              dataIndex: 'bodyFat',
              key: 'bodyFatMass',
              slots: {
                title: 'tmpBodyFatMassTitle',
                customRender: 'tmpBodyFatMassCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '肌肉率',
              dataIndex: 'musclePercentage',
              key: 'musclePercentage',
              slots: {
                title: 'tmpMusclePercentageTitle',
                customRender: 'tmpMusclePercentageCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '肌肉率',
              dataIndex: 'muscleMass',
              key: 'muscleMass',
              slots: {
                title: 'tmpMuscleMassTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '內臟脂肪',
              dataIndex: 'visceralFat',
              key: 'visceralFat',
              slots: {
                title: 'tmpVisceralFatTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '骨骼肌率',
              dataIndex: 'skeletalMuscleMassPercentage',
              key: 'skeletalMuscleMassPercentage',
              slots: {
                title: 'tmpSkeletalMuscleMassPercentageTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '水份率',
              dataIndex: 'bodyWater',
              key: 'bodyWater',
              slots: {
                title: 'tmpBodyWaterTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '基礎代謝率',
              dataIndex: 'bmr',
              key: 'bmr',
              slots: {
                title: 'tmpBmrTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '100px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '骨量',
              dataIndex: 'bone',
              key: 'bone',
              slots: {
                title: 'tmpBoneTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '100px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '身高',
              dataIndex: 'height',
              key: 'height',
              slots: {
                title: 'tmpHeightTitle',
                customRender: 'tmpHeightCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
          ]
        } else if (this.tabName === 'Spo2Chart') {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '血氧',
              dataIndex: 'spo2Lowest',
              key: 'spo2Lowest',
              slots: {
                title: 'tmpSpo2Title',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: 'ACT',
              dataIndex: 'act',
              key: 'act',
              slots: {
                title: 'tmpActTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
          ]
        } else if (this.tabName === 'BodyTemperatureChart') {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '體溫',
              dataIndex: 'bodyTemperature',
              key: 'bodyTemperature',
              slots: {
                title: 'tmpBodyTemperatureTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
          ]
        } else {
          return [
            {
              //title: '量測時間',
              dataIndex: 'measured',
              key: 'measured',
              width: '80px',
              fixed: 'left',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
              slots: {
                title: 'tmpRecordTimeTitle',
                customRender: 'tmpRecordTime',
              },
            },
            {
              //title: '舒張壓',
              dataIndex: 'systolic',
              key: 'systolic',
              slots: {
                title: 'tmpSystolicTitle',
                customRender: 'tmpMathRoundCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '收縮壓',
              dataIndex: 'diastolic',
              key: 'diastolic',
              slots: {
                title: 'tmpDiastolicTitle',
                customRender: 'tmpMathRoundCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '脈壓差',
              dataIndex: 'pp',
              key: 'pp',
              slots: {
                title: 'tmpPpTitle',
                customRender: 'tmpPPCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '脈搏',
              dataIndex: 'pulse',
              key: 'pulse',
              slots: {
                title: 'tmpPulseTitle',
                customRender: 'tmpMathRoundCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            // {
            //   //title: 'ARR,PC,AFRIB',
            //   dataIndex: 'arr',
            //   key: 'arr',
            //   slots: {
            //     title: 'tmpArrTitle',
            //     customRender: 'tmpCustomCell',
            //   },
            // },
            {
              //title: '空腹血糖',
              dataIndex: 'fasting',
              key: 'fasting',
              slots: {
                title: 'tmpFastingTitle',
                customRender: 'tmpBloodGlucoseFastingCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '餐前血糖',
              dataIndex: 'beforeMeal',
              key: 'beforeMeal',
              slots: {
                title: 'tmpBeforeMealTitle',
                customRender: 'tmpBloodGlucoseBeforeCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '餐後血糖',
              dataIndex: 'afterMeal',
              key: 'afterMeal',
              slots: {
                title: 'tmpAfterMealTitle',
                customRender: 'tmpBloodGlucoseAfterCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '體重',
              dataIndex: 'weight',
              key: 'weight',
              slots: {
                title: 'tmpBodyWeightTitle',
                customRender: 'tmpWeightCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: 'BMI',
              dataIndex: 'bmi',
              key: 'bmi',
              slots: {
                title: 'tmpBmiTitle',
                customRender: 'tmpMathRoundOneOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '體脂率',
              dataIndex: 'bodyFat',
              key: 'bodyFat',
              slots: {
                title: 'tmpBodyFatTitle',
                customRender: 'tmpMathRoundOneOrDashCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '總體脂量',
              dataIndex: 'bodyFatMass',
              key: 'bodyFat',
              slots: {
                title: 'tmpBodyFatMassTitle',
                customRender: 'tmpBodyFatMassCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '肌肉率',
              dataIndex: 'musclePercentage',
              key: 'musclePercentage',
              slots: {
                title: 'tmpMusclePercentageTitle',
                customRender: 'tmpMusclePercentageCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '肌肉率',
              dataIndex: 'muscleMass',
              key: 'muscleMass',
              slots: {
                title: 'tmpMuscleMassTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '內臟脂肪',
              dataIndex: 'visceralFat',
              key: 'visceralFat',
              slots: {
                title: 'tmpVisceralFatTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '骨骼肌率',
              dataIndex: 'skeletalMuscleMassPercentage',
              key: 'skeletalMuscleMassPercentage',
              slots: {
                title: 'tmpSkeletalMuscleMassPercentageTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '水份率',
              dataIndex: 'bodyWater',
              key: 'bodyWater',
              slots: {
                title: 'tmpBodyWaterTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '80px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '基礎代謝率',
              dataIndex: 'bmr',
              key: 'bmr',
              slots: {
                title: 'tmpBmrTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '100px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '骨量',
              dataIndex: 'bone',
              key: 'bone',
              slots: {
                title: 'tmpBoneTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '100px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '血氧',
              dataIndex: 'spo2Lowest',
              key: 'spo2Lowest',
              slots: {
                title: 'tmpSpo2Title',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: 'ACT',
              dataIndex: 'act',
              key: 'act',
              slots: {
                title: 'tmpActTitle',
                customRender: 'tmpMathRoundOrDashCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
            {
              //title: '體溫',
              dataIndex: 'bodyTemperature',
              key: 'bodyTemperature',
              slots: {
                title: 'tmpBodyTemperatureTitle',
                customRender: 'tmpMathRoundOneCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#e3f8f6',
                  },
                }
              },
            },
            {
              //title: '身高',
              dataIndex: 'height',
              key: 'height',
              slots: {
                title: 'tmpHeightTitle',
                customRender: 'tmpHeightCell',
              },
              width: '70px',
              align: 'center',
              customHeaderCell: () => {
                return {
                  style: {
                    background: '#eefbfc',
                  },
                }
              },
            },
          ]
        }
      },
      tableScrollSetting: function () {
        if (this.tabName === 'BodyWeightChart') {
          return { x: 1110 }
        }
        return {}
      },
    },
    beforeMount() {
      this.clearCurrentCaseData()
      this.clearMeasurementDetailData()
    },
    mounted() {
      //this.i18n.locale = 'en'
      this.fid = this.$route.params.fid
      this.isNaviTypeCase = this.$route.query.naviType === 'case' ? true : false
      console.log(this.$route.query)
      console.log(this.isNaviTypeCase)
      if (this.isNaviTypeCase === true) {
        const today = dayjs()
        const yearsago = dayjs().subtract(3, 'month')
        this.fromDate = yearsago.unix() * 1000
        this.toDate = today.unix() * 1000
        this.fromToDatelist = []
        this.fromToDatelist.push(yearsago)
        this.fromToDatelist.push(today)
      } else {
        this.fromDate = 0
        if (this.$route.query.fromDate) {
          this.fromDate = parseInt(this.$route.query.fromDate, 10)
        }
        this.toDate = 0
        if (this.$route.query.toDate) {
          this.toDate = parseInt(this.$route.query.toDate, 10)
        }
        if (this.fromDate > 0 && this.toDate > 0) {
          this.fromToDatelist = []
          this.fromToDatelist.push(dayjs(this.fromDate))
          this.fromToDatelist.push(dayjs(this.toDate))
        }
      }

      this.getCase(this.fid)

      // const idx = this.measurementDataAll.findIndex((element) => {
      //   //if (element.fid === parseInt(this.fid, 10)) {
      //   if (element.fid === this.fid) {
      //     return true
      //   } else {
      //     return false
      //   }
      // })
      // if (idx >= 0) {
      //   this.name = this.measurementDataAll[idx].name || ''
      //   this.measurementDataAll[idx].ids.forEach((element) => {
      //     if (element.idType === 'CUSTOM_ID') {
      //       this.customId = element.idValue
      //     }
      //   })
      // }

      this.startQuery()
      this.refresh++
      this.getMeasureThreshold()
    },
    methods: {
      ...mapActions({
        getMeasurementsById: 'measurement/getMeasurementsById',
        getCase: 'case/getCase',
        clearCurrentCaseData: 'case/clearCurrentCaseData',
        clearMeasurementDetailData: 'measurement/clearMeasurementDetailData',
        getMeasureThreshold: 'measureThreshold/getMeasureThreshold',
      }),
      roundDec(val, precision) {
        let convertNumber = val
        if (typeof val === 'string') {
          convertNumber = Number(val)
          if (isNaN(convertNumber)) {
            return 0
          }
        }
        return roundDecimal(convertNumber, precision)
      },
      customBloodGlucoseFasting(record) {
        // console.log(record)
        if (record && record.meal && record.meal === 'Fasting') {
          return roundDecimal(record.bloodGlucose, 1)
        }
        return ''
      },
      customBloodGlucoseBefore(record) {
        // console.log(record)
        if (record && record.meal && record.meal === 'Before Meal') {
          return roundDecimal(record.bloodGlucose, 1)
        }
        return ''
      },
      customBloodGlucoseAfter(record) {
        // console.log(record)
        if (record && record.meal && record.meal === 'After Meal') {
          return roundDecimal(record.bloodGlucose, 1)
        }
        return ''
      },
      customPP(num) {
        if (num === 0) {
          return null
        }
        return num
      },
      customHeight(text) {
        if (!text || text === '0&0') {
          return ''
        }
        if (
          this.measureThreshold?.stature?.unit === 'ft&in' &&
          typeof text === 'string'
        ) {
          return text.replace('&', "'") + '"'
        } else {
          return text
        }
      },
      customWeight(text) {
        const ret = this.roundDec(text, 2)
        if (ret == 0) {
          return ''
        } else {
          return ret
        }
      },
      checkIfZero(num) {
        if (num === 0) {
          return '--'
        }
        return num
      },
      convertTimeFormat(textTs) {
        if (textTs) {
          return dayjs(textTs).format('YYYY/MM/DD')
        } else {
          return ''
        }
      },
      onChangeDate(date, dateString) {
        if (dateString && dateString.length == 2) {
          const fromDate = dayjs(dateString[0]).startOf('day').unix()
          const toDate = dayjs(dateString[1]).endOf('day').unix()
          this.fromDate = fromDate * 1000
          this.toDate = toDate * 1000
          this.pagination.current = 1
          this.startQuery()
          this.refresh++
        }
      },
      startQuery() {
        if (this.fromDate <= 0 || this.toDate <= 0) {
          return
        }

        const postBody = {
          startTs: this.fromDate,
          endTs: this.toDate,
          fid: this.fid,
          pageNum: this.pagination.current,
          pageSize: this.pagination.pageSize,
        }

        if (this.tabName === 'BloodPressureChart') {
          postBody['type'] = 'bloodPressure'
        } else if (this.tabName === 'BloodGlucoseChart') {
          postBody['type'] = 'glucose'
        } else if (this.tabName === 'BodyWeightChart') {
          postBody['type'] = 'weight'
        } else if (this.tabName === 'Spo2Chart') {
          postBody['type'] = 'spo2'
        } else if (this.tabName === 'BodyTemperatureChart') {
          postBody['type'] = 'bodyTemperature'
        }

        this.getMeasurementsById(postBody)
      },
      getTotalCountString(total) {
        return this.$t('HR001.totalCount') + ` ${total}`
      },
      handleTableChange(pagination, filters, sorter) {
        console.log(pagination, filters, sorter)
        const pager = { ...this.pagination }
        pager.current = pagination.current
        this.pagination = pager
        this.startQuery()
      },
      getHeightUnit() {
        if (this.measureThreshold?.stature?.unit === 'ft&in') {
          return this.$t('MS004.ft')
        } else {
          // 公制
          return this.$t('MS004.cm')
        }
      },
      getTemperatureUnit() {
        if (this.measureThreshold?.temperature?.length > 0) {
          // 公制
          return this.measureThreshold?.temperature[0].unit || ''
        } else {
          return this.measureThreshold?.temperature?.unit || ''
        }
      },
      getWeightUnit() {
        if (this.measureThreshold?.bodyWeight?.unit === 'lbs') {
          return this.$t('MS004.lbs')
        } else {
          // 公制
          return this.$t('MS004.kg')
        }
      },
      getGlucoseUnit() {
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          return this.$t('MS002.mmol/L')
        } else {
          // 公制
          return this.$t('MS002.mg/dL')
        }
      },
      getMuscleMassAvg(record) {
        if (!record.muscleMass || !record.weight) {
          return ''
        }
        return this.checkIfZero(
          this.roundDec((record.muscleMass / record.weight) * 100, 1)
        )
      },
      tabClick(name) {
        this.tabName = name
        this.pagination.current = 1
        this.startQuery()
      },
      getBodyFatMass(record) {
        if (!record?.bodyFat || !record?.weight) {
          return ''
        }
        const bodyFatMass = (record?.bodyFat * record?.weight) / 100
        return this.roundDec(bodyFatMass, 1)
      },
      getMusclePercentage(record) {
        if (!record?.muscleMass || !record?.weight) {
          return ''
        }
        return this.roundDec((record?.muscleMass / record?.weight) * 100, 1)
      },
      clickSwitchMode() {
        if (this.displayMode === 'Table') {
          // Chart 還沒有其他圖表，都切回預設
          if (
            this.tabName !== 'BloodPressureChart' ||
            this.tabName !== 'BloodGlucoseChart'
          ) {
            this.tabName = 'BloodPressureChart'
            this.startQuery()
          }
          this.displayMode = 'Chart'
        } else {
          this.displayMode = 'Table'
        }
        return
      },
      disabledDate(current) {
        return current && current < dayjs().subtract(1, 'year')
      },
      transferArrAfibPc(text) {
        if (text == 0) {
          return '-'
        } else if (text == 1) {
          return '✓'
        } else {
          return '-'
        }
      },
    },
    watch: {
      detailData: function () {
        this.pagination.total = this.amount
      },
    },
  }
</script>
<style lang="less" scoped>
  .normal-color {
    color: #575756;
  }
  .warning-color {
    color: #ffb600;
  }
  .abnormal-color {
    color: #ff0000;
  }
  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }
  .info-title-font-left-style() {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    color: #575756;
  }
  .info-title-font-style() {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    justify-content: center;
    color: #575756;
  }
  .info-table-custom-title-font() {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5b5b5b;
    justify-content: center;
  }
  .info-table-custom-unit-font() {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #7a7a7a;
  }
  .info-table-custom-cell-font() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #717170;
  }

  .case-container {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    background-color: #e5e5e5;

    .case-top {
      width: 995px;
      height: 105px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .case-top-profile {
        width: 394px;
        height: 70px;
        margin-left: 24px;
        background: #4dbaa7;
        border-radius: 10px;
        position: relative;

        .case-top-profile-name {
          position: absolute;
          width: 185px;
          height: 16px;
          left: 12px;
          top: 27px;

          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 22px;

          /* identical to box height, or 53% */
          justify-content: center;
          display: flex;
          align-items: center;
          text-align: center;

          color: #ffffff;
        }

        .case-top-profile-code-title {
          position: absolute;
          width: 80px;
          height: 16px;
          left: 204px;
          top: 35px;

          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height, or 114% */

          display: flex;
          align-items: center;
          text-align: center;

          color: #ffffff;
        }

        .case-top-profile-code {
          position: absolute;
          width: 81px;
          height: 16px;
          left: 291px;
          top: 35px;

          font-family: Arial;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height, or 114% */

          display: flex;
          align-items: center;

          color: #ffffff;
        }
      }
      .case-top-condition-title {
        width: 103px;
        height: 16px;
        margin-left: 22px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 89% */

        display: flex;
        align-items: center;

        color: #5b5b5b;
      }
      .cast-top-select-date {
        width: 377px;
        margin-left: 10px;
      }
    }

    .case-middle {
      width: 995px;
      height: 253px;
      margin-top: 10px;

      background: #ffffff;
      border-radius: 10px;
    }

    .case-list {
      width: 995px;
      height: 920px;
      margin-top: 10px;
      margin-bottom: 100px;

      background: #ffffff;
      /* Elevation - 1 */

      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;

      .tab-control-area {
        margin: 0 auto;
        width: 935px;
        height: 45px;
        background: #fafafa;
        border: 4.04878px solid #ffffff;
        box-shadow: 4.04878px 4.04878px 4.04878px rgba(0, 0, 0, 0.03),
          inset -6.07317px -10.122px 6.07317px rgba(255, 255, 255, 0.6),
          inset 2.02439px 4.04878px 16.1951px rgba(0, 0, 0, 0.13);
        border-radius: 24.2927px;
        display: flex;
        flex-direction: row;
        > div {
          flex: 1;
          cursor: pointer;

          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          /* or 152% */

          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          opacity: 0.5;
          text-shadow: 0px 0px 6.07317px rgba(188, 188, 188, 0.15);
        }
        .active {
          background: #4dbaa7;
          box-shadow: -8.09756px -8.09756px 16.1951px rgba(255, 255, 255, 0.55),
            6.07317px 6.07317px 16.1951px rgba(0, 0, 0, 0.05);
          border-radius: 20.2439px;
          color: #fff;
        }
      }
      .tab-function-area {
        width: 900px;
        height: 550px;
        margin: 0 auto;
        background: #ffffff;
      }
      .siwtch-tab-list-btn {
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        justify-content: center; /*水平置中*/
        align-items: center; /*垂直置中*/
        height: 45px;
        cursor: pointer;
      }
      .case-list-title {
        padding-top: 20px;
        margin-left: 30px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #5b5b5b;
      }
      .case-list-table {
        padding-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 25px;

        .cusRecordTime {
          font-family: Arial;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #2e2e2e;
          justify-content: center;
        }

        .cusTableTitle {
          display: flex;
          justify-content: center;
          .info-table-custom-title-font();
          height: 40px;
        }
        .cusTableUnit {
          //margin-top: 5px;
          .info-table-custom-unit-font();
          height: 15px;
        }
        .cusTableCell {
          .info-table-custom-cell-font();
        }
        .cusTableCellNoColor {
          font-family: Arial;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
</style>
